<template>
 <nav class="navbar">
    <img src="@/assets/logo.svg" alt="Logotipo da Escola" class="logo-navbar">
    <div class="navbar-links">
      <span class="user-greeting">Olá, {{ parentName }}</span>
      <span class="time-left">{{ sessionTimeLeft }}</span>
      <button @click="logout">Sair</button>
    </div>
  </nav>
  <div class="page-container">
    <div v-if="loading" class="loading-container">
      Carregando...
      <!-- Substitua pelo seu spinner ou animação de carregamento -->
    </div>
    <div v-else-if="!hasStudents" class="no-students-container">
  <div class="no-students-icon">
    <!-- Substitua 'fa-frown' pelo ícone que preferir -->
    <i class="fas fa-frown"></i>
  </div>
  <div class="no-students-message">
    Não encontramos alunos disponíveis para matrícula no portal.
  </div>
  <a href="/" class="no-students-action">Voltar para a página inicial</a>
  <!-- Ou use router-link se estiver usando Vue Router -->
</div>
  <div v-else class="student-list-container">
      <div class="student-greeting">
  <h4 class="student-name">Selecione o aluno para continuar</h4>
</div>

 
    <div class="cards-container">
      <!-- Loop v-for para cada aluno no array students -->
      <div class="card" v-for="student in students" :key="student.RA">
  <div class="card-image-container">
    <img v-if="student.IMAGEM" :src="'data:image/jpeg;base64,' + student.IMAGEM" alt="Foto do aluno" class="student-icon">
    <img v-else src="@/assets/avatar.png" alt="Ícone do aluno" class="student-icon">
  </div>
  <div class="student-info">
    <h4><b>Ano:</b> {{ student.ANO }}</h4>
    <h4><b>Registro Acadêmico:</b> {{ student.RA }} </h4>
    <h4><b>Nome: </b>{{ student.NOME }}</h4>
    <h4><b>Curso:</b> {{ student.SERIE }} - {{ student.TURNO }}</h4>
    <h4><b>Situação:</b> {{ student.SITUACAO }}</h4>
  </div>
  <button class="confirm-button" @click="confirmSelection(student)">Confirmar</button>
</div>
    </div>
  </div>
  </div>

<!-- Modal de Termo de Autorização -->
<div v-if="showTermsModal" class="terms-modal">
  <div class="terms-modal-content">
    <h2>Termos de Autorização</h2>
    <div class="terms-text">
      <p>TERMO DE AUTORIZAÇÃO PARA PRÁTICA DE ATIVIDADE FÍSICA /ESPORTIVA</p>
      <p>Autorizo a participar das aulas de Educação Física, bem como de qualquer outra atividade esportiva promovida pelo Escola Villare, além de torneios e campeonatos externos. Informo também que meu filho(a) possui total saúde física e mental, não havendo, de meu conhecimento, nenhuma restrição à realização das atividades citadas.</p>
      ACONSELHAMOS QUE O RESPONSÁVEL SOMENTE AUTORIZE A PRÁTICA ESPORTIVA DE SEU FILHO(A) SE REALMENTE DISPUSER DE DADOS QUE COMPROVEM A SUA SITUAÇÃO FÍSICA ATUAL. CASO CONTRÁRIO, ORIENTAMOS PARA QUE SE CONSULTE UM MÉDICO.

      <p>TERMO DE AUTORIZAÇÃO PARA TRANSPORTE</p>
      Autorizo a escola a realizar o transporte de meu (minha) filho(a) da escola até o Espaço Poliesportivo, localizado à Rua Floriano Peixoto, 313, nos horários e dias das aulas esportivas e culturais nas quais ele (ela) se encontra inscrito(a), cujo transporte seja de responsabilidade da escola.
      <p>Autorizo também a escola a transportá-lo a este espaço nos eventos promovidos durante o período regular de aulas, cuja participação do aluno seja necessária para o desenvolvimento das atividades pedagógicas.
      </p>
          
    </div>
    <div class="terms-actions">
      <button @click="cancelTerms" class="terms-cancel-button">Cancelar</button>
      <button @click="agreeToTerms" class="terms-agree-button">Concordar e Continuar</button>
    
    </div>
  </div>
</div>
</template>

  <script lang="ts">
  import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router'; // Importe useRouter
    import { useStore } from 'vuex';
    import { useToast } from 'vue-toastification';
    import axios from 'axios';


  
  export default {
    setup() {
     const router = useRouter(); // Use useRouter para obter a instância do roteador
     const store = useStore(); // useStore é chamado aqui no topo do setup
     const loading = ref(false);
      const students = ref([]);
      const parentName = localStorage.getItem('parentName');//ref('Mr. Andy Hoppe DDS');  Nome seria definido dinamicamente
      const hasStudents = ref(true); // Começa assumindo que há alunos
      const showTermsModal = ref(false);
      const backendUrl = process.env.VUE_APP_API_URL;
      const toast = useToast();
      const sessionTimeLeft = ref('');

        // Função para calcular o tempo restante
        const calculateTimeLeft = () => {
          const expirationDateString = localStorage.getItem('expirationDate');
          const expirationDate = expirationDateString ? new Date(expirationDateString) : new Date();
          const now = new Date();
          const difference = expirationDate.getTime() - now.getTime();
          const timeLeftInSeconds = Math.max(Math.round(difference / 1000), 0);

          const minutes = Math.floor(timeLeftInSeconds / 60);
          const seconds = timeLeftInSeconds % 60;

          sessionTimeLeft.value = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        };

      // Atualizar a cada segundo
      setInterval(calculateTimeLeft, 1000);
      // Isto seria chamado quando o componente é montado, para buscar os dados da API
      onMounted(async () => {
        loading.value = true; // Ativa o carregamento
        try {
          // Supondo que o token Bearer esteja armazenado no localStorage
          const userToken = localStorage.getItem('bearerToken');
          const codUsuario = localStorage.getItem('codUsuario'); // Substitua pela lógica de obtenção do código do usuário

          // Faz a requisição para o endpoint do servidor Node.js
          const response = await axios.get(`${backendUrl}/students?codUsuario=${codUsuario}`);
          
          // Atualiza a lista de alunos com a resposta da API
          students.value = response.data;
          hasStudents.value = students.value.length > 0;
    
        } catch (error) {
          if (error instanceof Error) {
              // Agora você pode acessar error.message ou usar toString()
                toast.error(`Ops, não encontramos alunos: ${error.message}`);
              } else {
                // Tratar o caso em que o erro não é uma instância de Error
                // Talvez registrando o erro ou mostrando uma mensagem genérica
                toast.error('Ops, ocorreu um erro ao buscar os alunos.');
              }
        }finally {
          loading.value = false; // Desativa o carregamento após completar a busca
        }
      });
  
      function confirmSelection(student: any) {
        // Ative o modal de termos
        showTermsModal.value = true;

        // Guarde as informações do aluno, mas não redirecione ainda
        localStorage.setItem('selectedStudentRA', student.RA);
        localStorage.setItem('selectedStudentName', student.NOME);
        localStorage.setItem('StudentMatriculaRegular', student.IDHABILITACAOFILIAL);
        localStorage.setItem('StudentSerie', student.SERIE);
        localStorage.setItem('StudentTurno', student.TURNO);
        localStorage.setItem('StudentGenero', student.GENERO);
        localStorage.setItem('studentCourseCode', student.CODCURSO);
      }

      function agreeToTerms() {
        // Fechar o modal de termos
        showTermsModal.value = false;

        // Redireciona para o wizard de matrícula
        router.push({ name: 'WizardMatricula' });
      }

      function cancelTerms() {
      // O usuário cancelou, apenas feche o modal
      showTermsModal.value = false;
    }
      function logout() {
      
        localStorage.clear();
        // Limpa o estado do Vuex
        store.commit('clearToken'); // Ou a ação/mutação que você tem para limpar o token

        // Redireciona o usuário para a página de login
        router.push({ name: 'login' });
      }

      return {
        students,
        parentName,
        sessionTimeLeft,
        confirmSelection,
        agreeToTerms,
        cancelTerms,

        showTermsModal,
        hasStudents,
        loading, // Adiciona o loading aos dados retornados
        logout
      };
    },
  };
  </script>
  
  <style scoped>
@import "@/assets/navbar.css"; /* Importe os estilos da navbar aqui */

.student-info {
  text-align: left;
  width: 100%;
  padding: 0 1rem; /* Adiciona padding lateral */
}



.student-name {
  margin: 0;
  color: #333;
  font-size: 1.5em; /* Tamanho grande para o nome */
  /* Certifique-se de que não há estilos conflitantes aqui */
}
.student-list-container {
  background-color: #FED766; /* Cor de fundo amarela */
  padding: 20px;
  align-items: center; /* Centraliza os cartões horizontalmente */
  min-height: calc(100vh - 50px); /* Ajuste baseado na altura da sua navbar */
  box-sizing: border-box;
}

.cards-container {
  display: flex;
  flex-direction: column; /* Empilha os cartões verticalmente */
  align-items: center; /* Centraliza os cartões horizontalmente */
  gap: 20px; /* Espaço entre os cartões */
  padding: 20px;
}

.card {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin: 10px auto;
  width: 90%;
  max-width: 500px;
  box-sizing: border-box;
  display: flex; /* Adicionado para flexibilizar o layout */
  flex-direction: column; /* Mantém os elementos em coluna */
  align-items: center; /* Centraliza os elementos horizontalmente */
}

.card h2 {
  color: #333; /* Cor escura para o texto para contraste */
}

.confirm-button {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #333; /* Cor escura para o botão */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s; /* Transição suave para o hover */
}


.confirm-button:hover {
  background-color: #555; /* Um tom ligeiramente mais claro para o hover */
}


@media (max-width: 768px) { /* ou outro breakpoint que preferir */
  .card {
    width: 100%; /* Faz com que o card ocupe toda a largura em dispositivos móveis */
    margin: 5px 0; /* Ajuste a margem para dispositivos móveis */
  }
}



.student-icon {
  margin-bottom: 1rem; /* Adiciona margem abaixo da imagem */
  width: 100px; /* Diminui o tamanho da imagem */
  height: auto;
  border-radius: 50%; /* Faz a imagem ficar redonda */
}
.student-info {
  text-align: left;
  width: 100%;
  padding: 0 1rem; /* Adiciona padding lateral */
}

.student-info h4 {
  font-weight: normal;
  font-size: 0.9rem;
  margin: 0.50rem 0;
  color: #555; /* Torna o texto um pouco mais claro */
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ajuste conforme o necessário */
}

.no-students-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh; /* Ajuste para centralizar verticalmente na página */
  text-align: center;
  color: #333; /* Cor que combine com seu tema */
  font-size: 1.2rem;
  padding: 20px;
}

.no-students-icon {
  font-size: 64px; /* Tamanho do ícone */
  margin-bottom: 20px; /* Espaço entre ícone e texto */
}

.no-students-message {
  margin-bottom: 20px;
}

.no-students-action {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  background-color:#FED766; /* #f2f2f2; Cor de fundo do botão */
  color: #333; /* Cor do texto do botão */
  text-decoration: none; /* Remover sublinhado de links */
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.no-students-action:hover {
  background-color: #ddd; /* Cor de fundo ao passar o mouse */
}

/* Estilos para o modal de termos */
.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.terms-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  color: #333; /* Cor do texto */
  font-family: 'Arial', sans-serif; /* Altere conforme a fonte do seu tema */
}

.terms-text {
  margin-bottom: 20px;
  text-align: left; /* Alinhar o texto à esquerda */
  font-size: 14px; /* Tamanho do texto */
  line-height: 1.6; /* Espaçamento das linhas */
  height: 300px; /* Altura fixa para a área de texto */
  overflow-y: scroll; /* Adicionar barra de rolagem para texto longo */
}
.terms-modal h2 {
  text-align: center;
  margin-bottom: 20px;
}

.terms-actions {
  display: flex; /* Usar flexbox para alinhamento dos botões */
  justify-content: space-between; /* Separar os botões uniformemente */
  padding: 0 20px; /* Adicionar padding nas laterais para não tocar as bordas */
  margin-top: 20px;
}

.terms-actions button {
  margin-left: 10px;
}


.terms-agree-button, .terms-cancel-button {
  flex: 1; /* Fazer os botões terem a mesma largura */
  margin: 0 10px; /* Adicionar margem entre os botões */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px; /* Tamanho do texto dos botões */
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s; /* Transição suave para o hover */
}

.terms-agree-button {
  background-color: #4CAF50; /* Cor do botão Concordar */
  color: white;
}

.terms-agree-button:hover {
  background-color: #45a049; /* Cor do hover */
}

.terms-cancel-button {
  background-color: #f44336; /* Cor do botão Cancelar */
  color: white;
}

.terms-cancel-button:hover {
  background-color: #d32f2f; /* Cor do hover */
}

@media (max-width: 768px) {
  .terms-actions {
    flex-direction: column; /* Empilhar os botões verticalmente */
  }

  .terms-agree-button, .terms-cancel-button {
    margin-top: 10px; /* Adicionar espaço entre os botões quando empilhados */
    margin-bottom: 10px;
  }
}
</style>
