import { createApp } from 'vue';
import './assets/global.css';
import './assets/navbar.css';
import App from './App.vue';
import Toast, { PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css'; // Importe os estilos aqui
import router from './router';
import store from './store';
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// Opções de configuração para o Toast
const toastOptions: PluginOptions = {
  // Você pode definir suas opções aqui, por exemplo:
  /*position: "top-right",*/
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: true,
  rtl: false
};

const app = createApp(App);


app.use(store);
app.use(router);
app.use(VueFormWizard);
app.use(Toast, toastOptions); // Registre o plugin aqui

app.mount('#app');
