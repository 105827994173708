import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import StudentListView from '../views/StudentListView.vue'
import WizardMatricula from '../views/WizardMatriculaView.vue'
import { isSessionExpired } from '../auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/student-list',
    name: 'StudentList',
    component: StudentListView,
    meta: { requiresAuth: true }
  },
  {
    path: '/WizardMatricula',
    name: 'WizardMatricula',
    component: WizardMatricula,
    meta: { requiresAuth: true }
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


// Aqui é onde você adiciona o guardião de rota global
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    /*console.log(isSessionExpired(), 'isSessionExpired()');*/
    if (isSessionExpired()) {
      next({ name: 'login' }); // Redireciona para a página de login
    } else {
      next(); // Continua para a rota solicitada
    }
  } else {
    next(); // Se a rota não requer autenticação, continua normalmente
  }
});

export default router;

