// auth.ts
export const isSessionExpired = (): boolean => {
    const expirationDateString = localStorage.getItem('expirationDate');
    if (!expirationDateString) {
      return true;
    }
  
    const expirationDate = new Date(expirationDateString);
    return new Date() >= expirationDate;
  };
  