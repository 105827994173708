<template>
  <div class="view-container">
    <!-- Pode ter outros componentes de layout ou elementos aqui -->
    <login-form></login-form>
  </div>
</template>

<script  lang="ts">
import LoginForm from '@/components/LoginForm.vue';

export default {
  components: {
    LoginForm
  }
};
</script>

<style scoped>
.view-container {
  /* Estilos para o container da view, se necessário */
}
</style>
