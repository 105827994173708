<template>
  <div class="login-container">
    <!-- Adicione o logotipo da escola se disponível -->
    <img src="@/assets/logo.svg" alt="Logotipo villare" class="logo">
    
    <form @submit.prevent="handleLogin">
      <h1>Inscrição Poliesportivos</h1>
      <div class="form-group">
        <label for="username">Usuário</label>
        <input type="text" id="username" v-model="loginForm.username" required>
      </div>
      <div class="form-group">
        <label for="password">Senha</label>
        <input type="password" id="password" v-model="loginForm.password" required>
      </div>
      <div class="form-group">
        <button type="submit" :disabled="isSubmitting">
          {{ isSubmitting ? 'Carregando...' : 'Entrar' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios  from 'axios';
import { useToast } from 'vue-toastification';
import { isSessionExpired } from '@/auth';

export default {
  mounted() {
    if (isSessionExpired()) {
      localStorage.clear();
      // Exibir mensagem, se necessário
    }
  },

  setup() {
    const router = useRouter();
    const isSubmitting = ref(false);
    const toast = useToast();
    const backendUrl = process.env.VUE_APP_API_URL;
    const loginForm = ref({
      username: '',
      password: '',
    });

    function showSuccessMessage() {
      toast.success('Bem vindo!');
    }

    function showErrorMessage() {
      toast.error('Erro ao fazer login');
    }

    async function handleLogin() {
      isSubmitting.value = true;
        const credentials = {
          username: loginForm.value.username,
          password: loginForm.value.password
        };

        try {
        
          const authResponse = await axios.post(`${backendUrl}/authenticate`, credentials);
          const bearerToken = authResponse.data.token;
          const parentName = authResponse.data.parentName;
        
          const expiresIn = 20 * 60; // 15 minutos em segundos
          const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
      
        
          localStorage.setItem('bearerToken', bearerToken);
          localStorage.setItem('codUsuario', loginForm.value.username);
          localStorage.setItem('parentName', parentName);
          localStorage.setItem('parentEmail', authResponse.data.parentEmail);
          localStorage.setItem('expirationDate', expirationDate.toISOString());
          localStorage.setItem('refreshToken', authResponse.data.refresh_token);


          if (authResponse.data.user_valido === 1) {
            router.push({ name: 'StudentList' });
            showSuccessMessage();
          } else {
            // Se o código chegou aqui, algo deu errado pois a API já deve ter retornado um erro antes
            toast.error('Erro desconhecido.');
          }
        } catch (error: any) {
          let errorMessage = 'Erro ao fazer login ou validar usuário: ';
          if (error.response && error.response.status === 401) { // Verifica se é um erro 401
            errorMessage += error.response.data.error; // Usa a mensagem de erro personalizada do backend
          } else if (error.response && error.response.data && error.response.data.error) {
            errorMessage += error.response.data.error;
          } else if (error.message) {
            errorMessage += error.message;
          } else {
            errorMessage += 'Ocorreu um erro desconhecido.';
          }
          toast.error(errorMessage);
        }finally {
        isSubmitting.value = false;
      }
      }





    return {
      loginForm,
      isSubmitting,
      handleLogin,
      showSuccessMessage,
      showErrorMessage
    };
  },
};
</script>

<style scoped>

body {
  margin: 0;
  padding: 0;
}


.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #FED766; /* Cor amarela semelhante à da marca */
  font-family: 'Arial', sans-serif; /* Substitua pela fonte da marca se necessário */
}

.logo {
  margin-bottom: 2rem;
  height: 50px;
}

form {
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 300px; /* Ou a largura que melhor se adequar */
}

.form-group {
  margin-bottom: 1rem;
}

form h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem; /* Ajuste o tamanho da fonte conforme necessário */
  color: #333; /* Ou qualquer cor que se adeque ao seu design */
}

label {
  color: #333;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #333; /* Cor escura para o botão */
  color: white;
  cursor: pointer;

}

button:hover {
  background-color: #555; /* Um tom ligeiramente mais claro para o hover */
}
</style>
